<template>
  <div>
    <v-dialog
      v-model="createYearModel"
      persistent
      max-width="600px"
    >
        <v-card>
        <v-container>
            <v-row no-gutters>
              <v-text-field
                required
                v-model="nextYear"
                variant="outlined"
                hide-details
                :disabled="stage !== 'development'"
                label="Novo Ano"
              ></v-text-field>
            </v-row>
            <v-row no-gutters>
              <v-switch
                v-model="copyRecommended"
                color="teal"
                label="Copiar recomedações do ano anterior"
                hide-details
              ></v-switch>
            </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          color="error"
          variant="text"
          @click="refresh()"
          prepend-icon="mdi-cancel"
          >
            Cancelar
          </v-btn>
          <v-btn
          color="teal"
          variant="text"
          @click="copyRecommended ? createYear() : confirmChoice = true"
          prepend-icon="mdi-plus"
          >
            Criar
          </v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>

    <!-- REMOVE NEW USER -->
    <ConfirmDialog v-model="confirmChoice" :newTitle="confirmTitle" :newMessage="confirmMessage" :newOptions="confirmOptions" @consent="createYear()" @dissent="confirmChoice = false" />
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import axios from 'axios'
import ConfirmDialog from './ConfirmDialog.vue'
import { useUserStore } from '@/stores/userStore'
import { useSnackbarStore } from '@/stores/snackbarStore'
import { storeToRefs } from 'pinia'

const snackbarStore = useSnackbarStore()

const userStore = useUserStore()
const { currentUser } = storeToRefs(userStore)

const api = import.meta.env.VITE_API
const stage = import.meta.env.VITE_STAGE

const createYearModel = defineModel()
const emit = defineEmits(['refresh'])
const props = defineProps(['currentUser', 'branch'])

const copyRecommended = ref(false)
const nextYear = ref((new Date().getFullYear() + 1).toString())

const confirmChoice = ref(false)
const confirmTitle = ref('ATENÇÃO!')
const confirmMessage = ref('Criar um novo ano sem copiar as atividades recomendadas do ano anterior fará com que seja necessário configurá-las manualmente novamente. Deseja continuar?')
const confirmOptions = ref({ color: 'error', confirmText: 'Continuar' })

onMounted(() => {
  fetchIcons()
})

// Fetch icons from Material Design Icons API
const fetchIcons = async () => {
  try {
    const response = await fetch('https://cdn.jsdelivr.net/npm/@mdi/svg@7.4.47/meta.json')
    if (!response.ok) {
      throw new Error('Failed to fetch icons')
    }
    // const data = await response.json()
    // icons.value = data.icons.slice(0, 20) // Example: Show first 20 icons
  } catch (error) {
    console.error('Error fetching icons:', error)
    snackbarStore.openSnackbar('Não foi possível achar ícones')
  }
}

const createYear = async () => {
  await axios.get(api + '/status', { timeout: 2000 }).then(async () => {
    await axios.post(api + '/manager/activity/createYear', { author: currentUser.value.email, year: nextYear.value, copyChoice: copyRecommended.value }, { headers: { Authorization: 'Bearer ' + currentUser.value.token } })
      .then(response => {
        console.log(response.data)
        refresh(response.data)
      })
      .catch(error => {
        console.log(error)
        snackbarStore.openSnackbar('Não foi possível criar novo ano')
      })
  }).catch(error => {
    console.log('Error fetching status:', error)
    snackbarStore.openSnackbar()
  })
}

const refresh = (data) => {
  emit('refresh', data.year)
  createYearModel.value = false
  copyRecommended.value = false
}

</script>
