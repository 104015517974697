<template>
  <div>
    <v-dialog
      v-model="newActivityModel"
      persistent
      max-width="600px"
  >
      <v-card>
      <v-container>
          <v-row no-gutters class="mb-5">
            <v-text-field
                required
                v-model="newActivityName"
                variant="outlined"
                hide-details
                :rules="fieldRules"
                label="Nome da atividade"
                clearable
            ></v-text-field>
          </v-row>
          <v-row no-gutters>
            <v-textarea
              v-model="newActivityDescription"
              variant="outlined"
              hide-details
              :rules="fieldRules"
              filled
              label="Descrição"
              auto-grow
              clearable
            ></v-textarea>
          </v-row>
      </v-container>
      <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          color="error"
          variant="text"
          @click="newActivityModel = false"
          prepend-icon="mdi-cancel"
          >
          Cancelar
          </v-btn>
          <v-btn
          color="teal"
          variant="text"
          @click="createActivity"
          prepend-icon="mdi-plus"
          >
          Criar
          </v-btn>
      </v-card-actions>
      </v-card>
  </v-dialog>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import axios from 'axios'
import { useSnackbarStore } from '@/stores/snackbarStore'

const snackbarStore = useSnackbarStore()

const api = import.meta.env.VITE_API

const newActivityModel = defineModel()
const props = defineProps(['currentUser', 'catId'])
const emit = defineEmits(['refresh'])

const fieldRules = [
  v => !!v || 'Campo obrigatório'
]

const currentUser = ref(props.currentUser)
const categoryId = ref(props.catId)

const newActivityName = ref('')
const newActivityDescription = ref('')

watch(props, () => {
  categoryId.value = props.catId
})

const createActivity = () => {
  const newActivity = {
    name: newActivityName.value,
    description: newActivityDescription.value,
    recommended: [{
      year: new Date().getFullYear().toString(),
      months: []
    }],
    categoryId: categoryId.value
  }

  axios.get(api + '/status', { timeout: 2000 }).then(() => {
    axios.post(api + '/manager/activity', { author: currentUser.value.email, activity: newActivity }, { headers: { Authorization: 'Bearer ' + currentUser.value.token } })
      .then(async () => {
        refresh()
        await axios.post(api + '/manager/revision', { userEmail: currentUser.value.email }, { headers: { Authorization: 'Bearer ' + currentUser.value.token } })
          .catch(error => {
            snackbarStore.openSnackbar('Não foi possível salvar as alterações no histórico')
            console.log(error)
          })
      })
      .catch(error => {
        snackbarStore.openSnackbar('Não foi possível criar a atividade')
        console.log(error)
      })
  }).catch(error => {
    snackbarStore.openSnackbar('Não foi possível criar a atividade')
    console.log('Error fetching status:', error)
  })
}

const refresh = () => {
  emit('refresh')
  snackbarStore.openSnackbar('Ativdade criada com sucesso!', 'success')
  newActivityModel.value = false
  newActivityName.value = ''
  newActivityDescription.value = ''
}

</script>
