<template>
  <div>
    <v-dialog
    v-model="removeActivityModel"
    max-width="600px"
  >
    <v-card>
      <v-card-title>Apagar Atividade</v-card-title>
      <v-card-text class="mb-0 pb-0 text-justify">
      <p>
        Você pode <strong>apagar completamente a atividade {{ activityName }}</strong> a qualquer momento.
        Isto removerá todas as suas informações dos nossos servidores remotos.
        Esteja ciente, no entanto, que esta é uma <u>ação irreversível</u>, ou seja, os dados da atividade serão <u>permanentemente apagados</u>
        sem qualquer possibilidade de recuperação.
      </p>
    </v-card-text>
    <v-card-actions v-if="step === 0">
      <v-switch
        color="teal"
        v-model="aware"
        label="Estou ciente e quero continuar."
        inset
        class="ml-2"
      />

      <v-spacer />

      <v-btn
        color="teal"
        text
        large
        @click="step++"
        :disabled="!aware"
        :loading="loading"
        append-icon="mdi-arrow-right"
      >
        Prosseguir
      </v-btn>
    </v-card-actions>
    <v-card-actions v-if="step === 1">
      <v-btn
        color="error"
        variant="text"
        @click="cancel()"
      >
        Cancelar
      </v-btn>

      <v-spacer />

      <v-btn
        color="warning"
        variant="flat"
        large
        @click="removeActivity()"
        :loading="loading"
        append-icon="mdi-check"
      >
        Apagar Atividade
      </v-btn>
    </v-card-actions>
    </v-card>
  </v-dialog>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import axios from 'axios'
import { errorMessage } from '@/helpers/error'
import { useSnackbarStore } from '@/stores/snackbarStore'

const snackbarStore = useSnackbarStore()

const api = import.meta.env.VITE_API

const removeActivityModel = defineModel()
const props = defineProps(['currentUser', 'activityId'])
const emit = defineEmits(['refresh'])

const activityId = ref('')
const activityName = ref('')

const aware = ref(false)
const step = ref(0)

const currentUser = ref(props.currentUser)
const error = ref(false)
const erroMessage = ref('')
const loading = ref(false)

watch(props, () => {
  loading.value = false
  aware.value = false
  step.value = 0
  currentUser.value = props.currentUser
  activityId.value = props.activityId
})

const getActivityById = async () => {
  await axios.get(api + '/status', { timeout: 2000 }).then(async () => {
    await axios.get(api + '/activity', {
      params: { activityId: activityId.value },
      headers: { Authorization: 'Bearer ' + currentUser.value.token }
    })
      .then(response => {
        console.log(response.data);
        
        activityName.value = response.data.name
      })
      .catch(error => {
        console.log(error)
        snackbarStore.openSnackbar('Não foi possível achar a atividade')
      })
  }).catch(error => {
    console.log('Error fetching status:', error)
    snackbarStore.openSnackbar()
  })
}

const handleError = (err) => {
  loading.value = false
  erroMessage.value = errorMessage(err)
  error.value = true
}

const checkOnline = () => {
  if (!navigator.onLine) {
    erroMessage.value = 'É necessário uma conexão com a internet para prosseguir! Por favor, verifique suas configurações de rede ou tente novamente mais tarde.'
    error.value = true
    return false
  }
  return true
}

const removeActivity = async () => {
  if (!checkOnline()) return

  loading.value = true

  if (currentUser.value.admin) {
    getActivityById()
    
    await axios.get(api + '/status', { timeout: 2000 }).then(async () => {
      await axios.post(api + '/manager/activity/remove', { adminEmail: currentUser.value.email, activityId: activityId.value }, { headers: { Authorization: `Bearer ${currentUser.value.token}` } })
        .then(async () => {
          refresh()
          await axios.post(api + '/manager/revision', { userEmail: currentUser.value.email }, { headers: { Authorization: 'Bearer ' + currentUser.value.token } })
            .catch(error => {
              snackbarStore.openSnackbar('Não foi possível salvar as alterações no histórico')
              console.log(error)
            })
        })
        .catch(err => {
          snackbarStore.openSnackbar('Não foi possível remover a atividade')
          handleError(err)
        })
    }).catch(err => {
      snackbarStore.openSnackbar()
      handleError(err)
    })
  } else {
    console.log('Acesso negado')
  }
}

const cancel = () => {
  removeActivityModel.value = false
  aware.value = false
  step.value--
}

const refresh = () => {
  snackbarStore.openSnackbar('Atividade apagada com sucesso!', 'success')
  activityId.value = ''
  removeActivityModel.value = false
  loading.value = false
  aware.value = false
  step.value--
  emit('refresh')
}

</script>
