<template>
  <div>
    <v-navigation-drawer v-model="openAnimalList" temporary :width="300">
      <v-list>
        <v-list-item class="pl-1 text-h6">
          <v-btn
            v-if="currentUser.admin"
            color="teal"
            variant="text"
            @click="newCategoryModel = true"
            icon="mdi-plus"
            class="pa-0"
          ></v-btn>

          Categorias

          <v-autocomplete
            variant="outlined"
            color="#009688"
            :items="availableYears"
            v-model="selectedYear"
            return-object
            label="Selecione um ano..."
            @update:modelValue="openCreateYearModel()"
          >
            <template v-slot:item="{ props }">
              <v-list-item v-bind="props">
                <template v-if="props.title === 'Criar Novo'" v-slot:prepend>
                  <v-icon color="#009688"> mdi-plus-box </v-icon>
                </template>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-list-item>
        <v-list-item
          v-for="(category, index) in categoriesAndActivitiesStore.getAnimalCategories"
          :key="index"
          :prepend-icon="category.icon"
          @click="updateCategory(category)"
        >
          <v-list-item-title class="text-wrap">
            {{ category.name }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <!-- CREATE NEW CATEGORY -->
    <CreateCategoryDialog
      v-model="newCategoryModel"
      branch="animal"
      @refresh="emit('listCategories')"
    />

    <!-- CREATE NEW YEAR -->
    <CreateYearDialog v-model="createYearModel" branch="vegetal" @refresh="refresh" />
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import CreateCategoryDialog from "@/components/CreateCategoryDialog.vue";
import CreateYearDialog from "@/components/CreateYearDialog.vue";
import { useCategoriesAndActivitiesStore } from "@/stores/categoriesAndActivitiesStore";
import { useUserStore } from "@/stores/userStore";
import { storeToRefs } from "pinia";

const userStore = useUserStore();
const { currentUser } = storeToRefs(userStore);
const categoriesAndActivitiesStore = useCategoriesAndActivitiesStore();
const { activitiesList } = storeToRefs(categoriesAndActivitiesStore);

const openAnimalList = defineModel();
const emit = defineEmits(["refresh"]);
const props = defineProps(["vegetalCategories", "currentUser"]);

const newCategoryModel = ref(false);
const availableYears = ref([]);
const selectedYear = ref(new Date().getFullYear().toString());
const createYearModel = ref(false);

onMounted(() => {
  if (activitiesList.value.length > 0) {
    listAvailableYears();
  }
});

watch(
  activitiesList,
  (newData) => {
    if (newData.length > 0) {
      listAvailableYears();
    }
  },
  { immediate: true } // Ensure it runs when component mounts
);

const listAvailableYears = async () => {
  const yearsSet = new Set();
  try {
    activitiesList.value.forEach((activity) => {
      if (activity.category?.branch === "vegetal") {
        activity.recommended.forEach((year) => {
          yearsSet.add(year.year);
        });
      }
    });
    availableYears.value = Array.from(yearsSet);
    availableYears.value.push("Criar Novo");
  } catch (error) {
    snackbarStore.openSnackbar("Não foi possível carregar os anos disponíveis");
    console.log(error);
  }
};

const updateCategory = (newCategory) => {
  openAnimalList.value = false;
  emit("refresh", newCategory, selectedYear.value);
};

const openCreateYearModel = () => {
  if (selectedYear.value === "Criar Novo") {
    createYearModel.value = true;
  }
  // TO DEFINE: WHAT TO DO WHEN YEAR IS CLICKED
};

const refresh = async (newYear) => {
  await categoriesAndActivitiesStore.fetchData();

  if (newYear) {
    selectedYear.value = newYear;
  } else {
    selectedYear.value = new Date().getFullYear().toString();
  }

  createYearModel.value = false;
  listAvailableYears();
};
</script>
